/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ScaleLoader from 'react-spinners/ScaleLoader';
import styled from 'styled-components';
import {
  ErrorText,
  Link,
  Text,
  spacing
} from '@comicrelief/component-library';

import {
  CAMPAIGN_STRING
} from '../constants';

export const PageLinkLoaderWrapper = styled.div`
  text-align: center;
  margin: ${spacing('lg')} 0 ${spacing('md')};
`;

const PageLinkLoader = ({ UUID = null, campaignCode }) => {
  // While we can't use this as a timeout flag with the
  // async getSubmissionData function, we still need it
  // to retrigger the rerender for the msg
  const [showTimeoutMsg, setShowTimeoutMsg] = useState(false);
  const [isErroring, setIsErroring] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jGPageURL, setJGPageURL] = useState(null);
  const [formattedCampaignCode, setFormattedCampaignCode] = useState(null);

  // To avoid weird problems using useState within the async,
  // use a global var for both the flag AND the timer
  let hasTimedOut = false;
  let totalTimer;

  // Set a realistic retry period:
  const retryDuration = 5000;

  // How long we should keep retrying until throwing in the towel:
  const attemptDuration = 45000;

  // To allow us to cancel the GET...
  const cancelTokenSource = axios.CancelToken.source();

  // As a 404 'error' response from our getSubmissionData function is possible before
  // the user data gets everywhere it needs to, let's handle it accordingly:
  const handleError = (error) => {
    if (error && error.response && error.response.status
      && error.response.status === 404) {
      if (!hasTimedOut) waitAndRetry();
    } else {
      // Else, it's a real error, so handle appropriately:
      setIsErroring(true);
      setIsLoading(false);
      // Handle a *server* timeout too, just in case
      if (error.code === 'ECONNABORTED') {
        hasTimedOut = true;
        setShowTimeoutMsg(true);
      }
    }
  };

  // Try again after the retryDuration period
  const waitAndRetry = () => {
    setTimeout(() => {
      getSubmissionData();
    }, retryDuration);
  };

  // Wraps the Axios GET with the associated actions to make it easily reusable
  const getSubmissionData = async () => {
    try {
      await axios.get(`${process.env.GATSBY_FSU_SUBMIT_URL}/signup/${UUID}`, { timeout: 30000, cancelToken: cancelTokenSource.token })
        .then((response) => {
          // If we've got a response but no JG page URL yet
          if (response.data.data.jg_page_claim_url === null) {
            // Only rerun the whole thing if we're not out of time:
            if (!hasTimedOut) waitAndRetry();
          } else {
            // Else, we DO have an URL so save it to the state to use in render, and update our flag
            setJGPageURL(response.data.data.jg_page_claim_url);
            setIsLoading(false);
            // Cancel the totalTimeout timer as we're successful
            clearTimeout(totalTimer);
          }
        });
    } catch (error) {
      // This can either be a 404 'still can't find the user's
      // submission' error, or something else entirely
      handleError(error);
    }
  };

  // Triggered on initial mount
  const totalTimeout = () => {
    totalTimer = setTimeout(() => {
      hasTimedOut = true;
      setShowTimeoutMsg(true);
      setIsLoading(false);
      // Cancel GET request:
      cancelTokenSource.cancel();
    }, attemptDuration);
  };

  // Update our UX flags, start timer and trigger main function on initial mount:
  useEffect(() => {
    totalTimeout();
    setIsLoading(true);
    getSubmissionData();

    // Short-term fix to allow for a little more flexibility.
    // If we don't have a copy string to use, just fallback
    // to nothing as the copy still makes sense without it.
    const formatted = CAMPAIGN_STRING(campaignCode) || '';
    setFormattedCampaignCode(formatted);
  }, []);

  return (
    <PageLinkLoaderWrapper>

      {/* Inform the user something's happening: */}
      {(isLoading && !isErroring) && (
      <>
        <Text tag="p" size="l" weight="bold">
          Registering your
          {' '}
          {formattedCampaignCode}
          {' '}
          JustGiving page...
        </Text>

        <Text tag="p" size="m">
          This could take up to 30 seconds or so. Please don&apos;t refresh or leave the page.
        </Text>

        <ScaleLoader height={32} width={4} loading={isLoading} />
      </>
      )}

      {/* If we've been returned a page URL, show it: */}
      {(!isLoading && !isErroring && jGPageURL) && (
        <Text tag="p" size="l">
          <Link
            color="red"
            href={jGPageURL}
            type="button"
            target="self"
          >
            Activate your JustGiving page
          </Link>
        </Text>
      )}

      {/* If there's a 'proper' error thrown by handleError, let the user know: */}
      { (!isLoading && (isErroring || showTimeoutMsg)) && (
        <>
          {/* If we've hit some timeout related issue, direct the user to their inbox */}
          { showTimeoutMsg ? (
            <ErrorText weight="bold">
              Sorry, something went wrong.
              {' '}
              Please check your email inbox to finish setting up your JustGiving page.
            </ErrorText>
          ) : (
            /* Otherwise, a more general error message */
            <ErrorText weight="bold">
              Sorry, something went wrong. Please refresh the page and try again, or contact
              {' '}
              <Link href="mailto:fundraising@comicrelief.com">fundraising@comicrelief.com</Link>
              {' '}
              if the problem persists.
            </ErrorText>
          )}
        </>
      )}

    </PageLinkLoaderWrapper>
  );
};

PageLinkLoader.propTypes = {
  UUID: PropTypes.string,
  campaignCode: PropTypes.string.isRequired
};

export default PageLinkLoader;
